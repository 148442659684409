<template>
  <v-fade-transition mode="out-in">
    <div class="admin-wbs">
      <div class="admin-wbs--loading" v-if="isPageLoading">
        <v-progress-circular
          :width="5"
          color="grey"
          indeterminate
          :size="30"
          ></v-progress-circular>
        <div
          class="grey--text"
          :style="{
            'margin-top': '12px',
            'font-size': '14px'
          }"
          >
          Loading
        </div>
      </div>
      <v-layout wrap v-else>
        <v-flex
          xs12
          lg6
          pt-0
          pb-0
          >
          <admin-approver-card-industry
            title="Industry Leader Approver"
            :color="section_color"
            :approvers="industyApprovers"
            :loading="isIndustryApproverLoading"
            @add="startAddIndustryApprover"
            @edit="startEditIndustryApprover"
            @remove="startRemoveIndustryApprover">
          </admin-approver-card-industry>
        </v-flex>
        <v-flex
          xs12
          lg6
          pt-0
          pb-0
          >
          <admin-approver-card
            title="CI Leader Approver"
            :color="section_color"
            :approvers="ciApprovers"
            :loading="isCiApproverLoading"
            @add="startAddCiApprover"
            @edit="startEditCiApprover"
            @remove="startRemoveCiApprover">
          </admin-approver-card>
          <admin-approver-card
            title="Finance Leader Approver"
            :color="section_color"
            :approvers="financeApprovers"
            :loading="isFinanceApproverLoading"
            @add="startAddFinanceApprover"
            @edit="startEditFinanceApprover"
            @remove="startRemoveFinanceApprover">
          </admin-approver-card>
          <admin-approver-card
            title="WBS Management Team"
            :color="section_color"
            :approvers="wbsCodeEditors"
            :loading="isWbsCodeEditorLoading"
            @add="startAddWbsCodeEditor"
            @edit="startEditWbsCodeEditor"
            @remove="startRemoveWbsCodeEditor">
          </admin-approver-card>
        </v-flex>

        <confirm-dialog
          :card-style="{
            width: '450px'
          }"
          v-model="isRemovingApprover"
          :title="'Remove -- ' + edit.tableName"
          :btn-color="section_color"
          @click-yes="doRemove"
          >
          <div style="height:60px;">
            Do you confirm to remove
            <b>{{ edit.target.name }}</b>
            <template v-if="edit.industry">
              of <b>{{ edit.industry }}</b>
            </template>
            from
            <b>{{ edit.tableName }}</b>?
          </div>
        </confirm-dialog>

        <admin-edit-dialog
          :card-style="{
            width: '600px'
          }"
          v-model="isEditingApprover"
          :title="edit.typeText + ' -- ' + edit.tableName"
          :btn-color="section_color"
          :role="edit.role"
          :roles="edit.roles"
          :target="edit.target"
          :notified="edit.notified"
          :industry="edit.industry"
          :industry-selection="isIndustrySelectionShown"
          @click-done="saveEdit"
          >
        </admin-edit-dialog>
      </v-layout>
    </div>
  </v-fade-transition>
</template>

<script>
import { section_color } from '@/config'
import { mapActions, mapGetters } from 'vuex'

import { xPost } from '@/functions/http/axios'

import adminApproverCard from '@/components/app/admin/approver-card/default'
import adminApproverCardIndustry from '@/components/app/admin/approver-card/industry'
import adminEditDialog from '@/components/app/admin/edit-dialog'

import confirmDialog from '@/components/vuetify-extension/confirm-dialog'

export default {
  components: {
    adminApproverCard,
    adminApproverCardIndustry,
    adminEditDialog,
    confirmDialog
  },
  data () {
    return {
      // status
      isPageLoading: true,
      isIndustryApproverLoading: true,
      isCiApproverLoading: true,
      isFinanceApproverLoading: true,
      isWbsCodeEditorLoading: true,
      isRemovingApprover: false,
      isEditingApprover: false,

      // props
      edit: {
        typeText: null,
        tableCode: null,
        tableName: null,
        industry: null,
        role: null,
        roles: [],
        notified: null,
        target: {
          email: null,
          name: null,
          icon: null
        }
      },

      // data
      industyApprovers: [],
      ciApprovers: [],
      financeApprovers: [],
      wbsCodeEditors: []
    }
  },
  computed: {
    // vuex
    ...mapGetters('user', [
      'loginStatus'
    ]),

    // params
    section_color () {
      return section_color['wbs']
    },
    isIndustrySelectionShown () {
      return (this.edit.tableCode == 'indt')
    }
  },
  methods: {
    // vuex
    ...mapActions('app', ['setMainTitle']),

    // action
    startAddIndustryApprover () {
      this.clearEditProps()
      this.edit.typeText = 'Add'
      this.edit.tableCode = 'indt'
      this.edit.tableName = 'Industry Leader Approver'
      this.edit.roles = ['Supporter']
      this.isEditingApprover = true
    },
    startAddCiApprover (item) {
      this.clearEditProps()
      this.edit.typeText = 'Add'
      this.edit.tableCode = 'ci'
      this.edit.tableName = 'CI Leader Approver'
      this.edit.roles = ['Supporter']
      this.isEditingApprover = true
    },
    startAddFinanceApprover (item) {
      this.clearEditProps()
      this.edit.typeText = 'Add'
      this.edit.tableCode = 'fin'
      this.edit.tableName = 'Finance Leader Approver'
      this.edit.roles = ['Supporter']
      this.isEditingApprover = true
    },
    startAddWbsCodeEditor (item) {
      this.clearEditProps()
      this.edit.typeText = 'Add'
      this.edit.tableCode = 'ce'
      this.edit.tableName = 'WBS Management Team'
      this.edit.roles = ['Supporter', 'Viewer']
      this.isEditingApprover = true
    },
    startEditIndustryApprover (item) {
      this.clearEditProps()
      this.edit.typeText = 'Edit'
      this.edit.tableCode = 'indt'
      this.edit.tableName = 'Industry Leader Approver'
      this.edit.industry = item.industry
      this.edit.roles = ['Supporter']
      this.edit.role = item.role
      this.edit.notified = item.notified
      this.edit.target.email = item.user.email
      this.edit.target.name = item.user.name
      this.edit.target.icon = item.user.icon
      this.isEditingApprover = true
    },
    startEditCiApprover (item) {
      this.clearEditProps()
      this.edit.typeText = 'Edit'
      this.edit.tableCode = 'ci'
      this.edit.tableName = 'CI Leader Approver'
      this.edit.roles = ['Supporter']
      this.edit.role = item.role
      this.edit.notified = item.notified
      this.edit.target.email = item.user.email
      this.edit.target.name = item.user.name
      this.edit.target.icon = item.user.icon
      this.isEditingApprover = true
    },
    startEditFinanceApprover (item) {
      this.clearEditProps()
      this.edit.typeText = 'Edit'
      this.edit.tableCode = 'fin'
      this.edit.tableName = 'Finance Leader Approver'
      this.edit.roles = ['Supporter']
      this.edit.role = item.role
      this.edit.notified = item.notified
      this.edit.target.email = item.user.email
      this.edit.target.name = item.user.name
      this.edit.target.icon = item.user.icon
      this.isEditingApprover = true
    },
    startEditWbsCodeEditor (item) {
      this.clearEditProps()
      this.edit.typeText = 'Edit'
      this.edit.tableCode = 'ce'
      this.edit.tableName = 'WBS Management Team'
      this.edit.roles = ['Supporter', 'Viewer']
      this.edit.role = item.role
      this.edit.notified = item.notified
      this.edit.target.email = item.user.email
      this.edit.target.name = item.user.name
      this.edit.target.icon = item.user.icon
      this.isEditingApprover = true
    },
    startRemoveIndustryApprover (item) {
      this.clearEditProps()
      this.edit.tableCode = 'indt'
      this.edit.tableName = 'Industry Leader Approver'
      this.edit.industry = item.industry
      this.edit.role = item.role
      this.edit.target.email = item.user.email
      this.edit.target.name = item.user.name
      this.edit.target.icon = item.user.icon
      this.isRemovingApprover = true
    },
    startRemoveCiApprover (item) {
      this.clearEditProps()
      this.edit.tableCode = 'ci'
      this.edit.tableName = 'CI Leader Approver'
      this.edit.role = item.role
      this.edit.target.email = item.user.email
      this.edit.target.name = item.user.name
      this.edit.target.icon = item.user.icon
      this.isRemovingApprover = true
    },
    startRemoveFinanceApprover (item) {
      this.clearEditProps()
      this.edit.tableCode = 'fin'
      this.edit.tableName = 'Finance Leader Approver'
      this.edit.role = item.role
      this.edit.target.email = item.user.email
      this.edit.target.name = item.user.name
      this.edit.target.icon = item.user.icon
      this.isRemovingApprover = true
    },
    startRemoveWbsCodeEditor (item) {
      this.clearEditProps()
      this.edit.tableCode = 'ce'
      this.edit.tableName = 'WBS Management Team'
      this.edit.role = item.role
      this.edit.target.email = item.user.email
      this.edit.target.name = item.user.name
      this.edit.target.icon = item.user.icon
      this.isRemovingApprover = true
    },
    async doRemove () {
      if (!this.edit.tableCode) return

      let snackbarParam = { type: 'error', text: 'Fail to remove' }
      this.isRemovingApprover = false
      this.$eventBus.$emit('snackbar', { type: 'loading', text: 'Removing Approver' })
      try {
        const res = await xPost(
          'admin',
          'rem_wbs_' + this.edit.tableCode,
          {
            loginStatus: this.loginStatus,
            email: this.edit.target.email,
            industry: this.edit.industry
          }
        )
        if (res.data.status === 200) {
          snackbarParam = { type: 'success', text: 'Approver Removed' }
        } else {
          try {
            if (res.data.message === 'Authorize Failed') {
              this.$router.push('/login')
              return []
            }
          } catch (e) {}
          throw ''
        }
      } catch (err) {
        console.log('Error [remove approver]')
        // console.log(err)
      }
      await this.refreshAllTables()
      this.$eventBus.$emit('snackbar', snackbarParam)
      this.clearEditProps()
    },
    async saveEdit (item) {
      if (!this.edit.tableCode) return

      let index_email = -1
      switch (this.edit.tableCode) {
        case 'indt':
          index_email = this.industyApprovers.findIndex(el => (el.user.email == item.target.email) && (el.industry == item.industry))
          break;
      
        case 'ci':
          index_email = this.ciApprovers.findIndex(el => el.user.email == item.target.email)
          break;
      
        case 'fin':
          index_email = this.financeApprovers.findIndex(el => el.user.email == item.target.email)
          break;
      
        case 'ce':
          index_email = this.wbsCodeEditors.findIndex(el => el.user.email == item.target.email)
          break;
      
        default:
          break;
      }

      if ((this.edit.target.email != item.target.email) && index_email > -1) {
        this.$eventBus.$emit('snackbar', { type: 'warning', text: 'Approver already exists' })
        return
      }

      let snackbarParam = { type: 'error', text: 'Fail to save' }
      this.$eventBus.$emit('snackbar', { type: 'loading', text: 'Saving' })
      try {
        // arrangement
        let role = this.edit.role
        let new_role = item.role
        if (this.edit.tableCode == 'ce') {
          if (role == 'Approver') {
            role = 'Editor'
          }
          if (new_role == 'Approver') {
            new_role = 'Editor'
          }
        } 

        const res = await xPost(
          'admin',
          'edit_wbs_' + this.edit.tableCode,
          {
            loginStatus: this.loginStatus,
            email: this.edit.target.email,
            new_email: item.target.email,
            new_name: item.target.name,
            industry: this.edit.industry,
            new_industry: item.industry,
            role,
            new_role,
            notified: item.notified
          }
        )
        if (res.data.status === 200) {
          snackbarParam = { type: 'success', text: 'Approver Saved' }
          await this.refreshAllTables()
          this.isEditingApprover = false
          this.clearEditProps()
        } else {
          try {
            if (res.data.message === 'Authorize Failed') {
              this.$router.push('/login')
              return []
            }
          } catch (e) {}
          throw ''
        }
      } catch (err) {
        console.log('Error [remove add/edit]')
        // console.log(err)
      }
      this.$eventBus.$emit('snackbar', snackbarParam)
    },

    // axios
    async getIndustryApprover(loading) {
      loading = loading || false
      try {
        if (loading) this.isIndustryApproverLoading = true
        const res = await xPost(
            'admin',
            'getwbsindtapprover',
            {
              loginStatus: this.loginStatus
            }
          )
          if (res.data.status === 200 && res.data.data) {
            const data = res.data.data
            this.industyApprovers = data.map(el => ({
              role: el.Role,
              industry: el.Industry,
              user: {
                email: el.Email,
                name: el.Name,
                icon: el.Icon
              },
              notified: el.Notified,
              deletable: el.Role != 'Approver',
              key: el.Email + '-' + el.Industry
            }))
          } else {
            try {
              if (res.data.message === 'Authorize Failed') {
                this.$router.push('/login')
              } else if (res.data.detail === "No Admin") {
                this.$router.push('/')
              }
            } catch (e) {}
            throw ''
          }
      } catch (err) {
        console.log('Error [get industy approvers]')
        // console.log(err)
      }
      this.isIndustryApproverLoading = false
    },
    async getCiApprover(loading) {
      loading = loading || false
      try {
        if (loading) this.isCiApproverLoading = true
        const res = await xPost(
            'admin',
            'getwbsciapprover',
            {
              loginStatus: this.loginStatus
            }
          )
          if (res.data.status === 200 && res.data.data) {
            const data = res.data.data
            this.ciApprovers = data.map(el => ({
              role: el.Role,
              user: {
                email: el.Email,
                name: el.Name,
                icon: el.Icon
              },
              notified: el.Notified,
              deletable: el.Role != 'Approver',
              key: el.Email
            }))
          } else {
            try {
              if (res.data.message === 'Authorize Failed') {
                this.$router.push('/login')
              } else if (res.data.detail === "No Admin") {
                this.$router.push('/')
              }
            } catch (e) {}
            throw ''
          }
      } catch (err) {
        console.log('Error [get ci approvers]')
        // console.log(err)
      }
      this.isCiApproverLoading = false
    },
    async getFinanceApprover(loading) {
      loading = loading || false
      try {
        if (loading) this.isFinanceApproverLoading = true
        const res = await xPost(
            'admin',
            'getwbsfinapprover',
            {
              loginStatus: this.loginStatus
            }
          )
          if (res.data.status === 200 && res.data.data) {
            const data = res.data.data
            this.financeApprovers = data.map(el => ({
              role: el.Role,
              user: {
                email: el.Email,
                name: el.Name,
                icon: el.Icon
              },
              notified: el.Notified,
              deletable: el.Role != 'Approver',
              key: el.Email
            }))
          } else {
            try {
              if (res.data.message === 'Authorize Failed') {
                this.$router.push('/login')
              } else if (res.data.detail === "No Admin") {
                this.$router.push('/')
              }
            } catch (e) {}
            throw ''
          }
      } catch (err) {
        console.log('Error [get finance approvers]')
        // console.log(err)
      }
      this.isFinanceApproverLoading = false
    },
    async getWbsCodeEditor(loading) {
      loading = loading || false
      try {
        if (loading) this.isWbsCodeEditorLoading = true
        const res = await xPost(
            'admin',
            'getwbscodeeditor',
            {
              loginStatus: this.loginStatus
            }
          )
          if (res.data.status === 200 && res.data.data) {
            const data = res.data.data
            this.wbsCodeEditors = data.map(el => ({
              role: (el.Role == 'Editor') ? 'Approver' : el.Role,
              user: {
                email: el.Email,
                name: el.Name,
                icon: el.Icon
              },
              notified: el.Notified,
              deletable: el.Role != 'Editor',
              key: el.Email
            }))
          } else {
            try {
              if (res.data.message === 'Authorize Failed') {
                this.$router.push('/login')
              } else if (res.data.detail === "No Admin") {
                this.$router.push('/')
              }
            } catch (e) {}
            throw ''
          }
      } catch (err) {
        console.log('Error [get wbs code editor approvers]')
        // console.log(err)
      }
      this.isWbsCodeEditorLoading = false
    },

    // function
    async refreshAllTables() {
      const gia = this.getIndustryApprover()
      const gca = this.getCiApprover()
      const gfa = this.getFinanceApprover()
      const gce = this.getWbsCodeEditor()
      const res_gia = await gia
      const res_gca = await gca
      const res_gfa = await gfa
      const res_gce = await gce
    },
    clearEditProps() {
      this.edit.typeText = null
      this.edit.tableCode = null
      this.edit.tableName = null
      this.edit.industry = null
      this.edit.roles = []
      this.edit.role = null
      this.edit.notified = null
      this.edit.target.email = null
      this.edit.target.name = null
      this.edit.target.icon = null
    },

    // init
    async init() {
      await this.refreshAllTables()
    }
  },
  async created () {
    this.setMainTitle('CI WBS Admin Setting')
    let init = await this.init()
    this.isPageLoading = false
  }
}
</script>

<style lang="sass">
  .admin-wbs
    width: 100%

    button
      margin-top: 3px
      margin-bottom: 3px
    

    .admin-wbs--loading
      width: 100%
      text-align: center
      padding-top: calc(50vh - 85px)
    
  
</style>
