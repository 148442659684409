var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-fade-transition',{attrs:{"mode":"out-in"}},[_c('div',{staticClass:"admin-wbs"},[(_vm.isPageLoading)?_c('div',{staticClass:"admin-wbs--loading"},[_c('v-progress-circular',{attrs:{"width":5,"color":"grey","indeterminate":"","size":30}}),_c('div',{staticClass:"grey--text",style:({
          'margin-top': '12px',
          'font-size': '14px'
        })},[_vm._v(" Loading ")])],1):_c('v-layout',{attrs:{"wrap":""}},[_c('v-flex',{attrs:{"xs12":"","lg6":"","pt-0":"","pb-0":""}},[_c('admin-approver-card-industry',{attrs:{"title":"Industry Leader Approver","color":_vm.section_color,"approvers":_vm.industyApprovers,"loading":_vm.isIndustryApproverLoading},on:{"add":_vm.startAddIndustryApprover,"edit":_vm.startEditIndustryApprover,"remove":_vm.startRemoveIndustryApprover}})],1),_c('v-flex',{attrs:{"xs12":"","lg6":"","pt-0":"","pb-0":""}},[_c('admin-approver-card',{attrs:{"title":"CI Leader Approver","color":_vm.section_color,"approvers":_vm.ciApprovers,"loading":_vm.isCiApproverLoading},on:{"add":_vm.startAddCiApprover,"edit":_vm.startEditCiApprover,"remove":_vm.startRemoveCiApprover}}),_c('admin-approver-card',{attrs:{"title":"Finance Leader Approver","color":_vm.section_color,"approvers":_vm.financeApprovers,"loading":_vm.isFinanceApproverLoading},on:{"add":_vm.startAddFinanceApprover,"edit":_vm.startEditFinanceApprover,"remove":_vm.startRemoveFinanceApprover}}),_c('admin-approver-card',{attrs:{"title":"WBS Management Team","color":_vm.section_color,"approvers":_vm.wbsCodeEditors,"loading":_vm.isWbsCodeEditorLoading},on:{"add":_vm.startAddWbsCodeEditor,"edit":_vm.startEditWbsCodeEditor,"remove":_vm.startRemoveWbsCodeEditor}})],1),_c('confirm-dialog',{attrs:{"card-style":{
          width: '450px'
        },"title":'Remove -- ' + _vm.edit.tableName,"btn-color":_vm.section_color},on:{"click-yes":_vm.doRemove},model:{value:(_vm.isRemovingApprover),callback:function ($$v) {_vm.isRemovingApprover=$$v},expression:"isRemovingApprover"}},[_c('div',{staticStyle:{"height":"60px"}},[_vm._v(" Do you confirm to remove "),_c('b',[_vm._v(_vm._s(_vm.edit.target.name))]),(_vm.edit.industry)?[_vm._v(" of "),_c('b',[_vm._v(_vm._s(_vm.edit.industry))])]:_vm._e(),_vm._v(" from "),_c('b',[_vm._v(_vm._s(_vm.edit.tableName))]),_vm._v("? ")],2)]),_c('admin-edit-dialog',{attrs:{"card-style":{
          width: '600px'
        },"title":_vm.edit.typeText + ' -- ' + _vm.edit.tableName,"btn-color":_vm.section_color,"role":_vm.edit.role,"roles":_vm.edit.roles,"target":_vm.edit.target,"notified":_vm.edit.notified,"industry":_vm.edit.industry,"industry-selection":_vm.isIndustrySelectionShown},on:{"click-done":_vm.saveEdit},model:{value:(_vm.isEditingApprover),callback:function ($$v) {_vm.isEditingApprover=$$v},expression:"isEditingApprover"}})],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }